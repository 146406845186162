import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useResizeObserver } from 'usehooks-ts';

import {
    Modal,
    Table,
    Tag,
    Form,
    Input,
    InputNumber,
    Select,
    Flex,
    Space,
    Checkbox,
    notification,
    Tooltip,
    Button,
    Upload,
    Switch,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import type { UploadProps } from 'antd/es/upload';
import type { GetRef } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import {
    LoadingOutlined,
    ReloadOutlined,
    EditOutlined,
    FilterFilled,
    AppstoreAddOutlined,
    QuestionCircleOutlined,
    CheckCircleFilled,
    PaperClipOutlined,
    CheckOutlined,
    CheckCircleOutlined,
    MessageFilled,
    WarningOutlined,
    FormOutlined,
} from '@ant-design/icons';

import Toolbar from '@controls/toolbar/toolbar';
import Filter from '@controls/filter/filter';
import FormHeader from '@controls/form-header/form-header';
import ConsignmentCard from '@controls/consignment-card/consignment-card';
import TariffCard from '@controls/tariff-card/tariff-card';

import Packages from './packages';

import { exception, securityRestriction } from '@extensions/notification';

import { userLoaded, setFilter } from '@store/actions';
import { useAppSelector, useAppDispatch } from '@store/hooks';
import { delayAction, toFinanceString, getEnumList } from '@extensions/utils';
import { serverFetch } from '@src/core/server';

import { IUserSession } from '@entities/user-session';
import { IBill } from '@entities/bill';
import { IBillFilter } from '@entities/bill-filter';
import { IBillPackage } from '@entities/bill-package';
import { ICity } from '@entities/city';
import { IConsigneeTariffSetting } from '@entities/consignee-tariff-setting';
import { ITruck } from '@entities/truck';
import { IUser } from '@entities/user';
import { ICountry } from '@entities/country';
import { IConsignmentTotals } from '@entities/consignment-totals';
import { ICargoCarrier } from '@entities/cargo-carrier';

import { Permission, hasPermission } from '@enums/permission';
import { UnitType } from '@enums/unit-type';
import { WeightType } from '@enums/weight-type';
import { UserType } from '@enums/user-type';
import { IEnumItem } from '@enums/enum-item';
import { BillStatus, enumLabel as billStatusLabel } from '@enums/bill-status';

import { TruckIcon, AirplaneIcon, CalculatorIcon, SumIcon } from '@icons/index';

import './bills.css';

const dayjs = require('dayjs');
var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

type TableRef = GetRef<typeof Table>;

const filterContext: string = 'ImportTariffs';

const Tariffs = () => {
    const { TextArea } = Input;

    const { truckId } = useParams();

    const initFilter: IBillFilter = {
        truckIds: [truckId as string],
        includeDocuments: true,
    };

    var Buffer = require('buffer/').Buffer;

    const tableRef = useRef<TableRef>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const { width = 0, height = 0 } = useResizeObserver({
        ref: containerRef,
        box: 'border-box',
    });

    const filterRef = useRef<HTMLDivElement>(null);
    const { width: filterWidth = 0, height: filterHeight = 0 } = useResizeObserver({
        ref: filterRef,
        box: 'border-box',
    });

    const [bulkTariffForm] = Form.useForm();
    const [editTariffForm] = Form.useForm();
    const [awbForm] = Form.useForm();

    const setBulkCityRequired = Form.useWatch('setCityRequired', bulkTariffForm);

    const setCityRequired = Form.useWatch('setCityRequired', editTariffForm);
    const setVolumeWeightRequired = Form.useWatch('setVolumeWeightRequired', editTariffForm);
    const setGrossWeightRequired = Form.useWatch('setGrossWeightRequired', editTariffForm);
    const isTotalPriceManual = Form.useWatch('isTotalPriceManual', editTariffForm);

    const [api, contextHolder] = notification.useNotification();
    const [modal, modalContextHolder] = Modal.useModal();

    const d = useAppDispatch();
    const userSession = useAppSelector<IUserSession>((s) => s.userSession);
    const filter = useAppSelector<IBillFilter>((s) => s.filters[filterContext]);

    const [bills, setBills] = useState<Array<IBill>>([]);
    const [currentBill, setCurrentBill] = useState<IBill>();

    const [selectedIds, setSelectedIds] = useState<React.Key[]>([]);
    const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const [bulkTariffOpen, setBulkTariffOpen] = useState<boolean>(false);
    const [editTariffOpen, setEditTariffOpen] = useState<boolean>(false);
    const [awbOpen, setAwbOpen] = useState<boolean>(false);

    const [showFilter, setShowFilter] = useState<boolean>(true);

    const [openPackageDialog, setOpenPackageDialog] = useState<boolean>(false);

    const [packages, setPackages] = useState<Array<IBillPackage>>([]);
    const [truck, setTruck] = useState<ITruck>();
    const [cities, setCities] = useState<Array<ICity>>([]);
    const [tariffSettings, setTariffSettings] = useState<Array<IConsigneeTariffSetting>>([]);
    const [contractors, setContractors] = useState<Array<IUser>>([]);
    const [countries, setCountries] = useState<Array<ICountry>>([]);
    const [airCargoCarriers, setAirCargoCarriers] = useState<Array<ICargoCarrier>>([]);

    const [lastSelectedKey, setLastSelectedKey] = useState<React.Key>();
    const [consignmentTotals, setConsignmentTotals] = useState<IConsignmentTotals>();
    const [processedTotals, setProcessedTotals] = useState<IConsignmentTotals>({
        totalBoxQty: 0,
        totalVolumeWeight: 0,
        totalPriceUsd: 0,
        totalFlightPriceUsd: 0,
    });
    const [statuses] = useState<Array<IEnumItem>>(getEnumList(BillStatus, billStatusLabel));

    useEffect(() => {
        if (!refreshRequired) return;

        let cleanup = false;

        const fetchData = async () => {
            bulkTariffForm.resetFields();
            editTariffForm.resetFields();

            setOpenPackageDialog(false);
            setBulkTariffOpen(false);
            setEditTariffOpen(false);

            setLoading(true);

            setBills([]);
            setCities([]);
            setSelectedIds([]);
            setCurrentBill(undefined);

            let promises = [
                await serverFetch(`trucks/${truckId}`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения машины', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cities', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения городов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`consignees/tariffsettings`, { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения настроек тарифа грузополучателей', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('users', { method: 'GET', queryParams: { types: UserType.Supplier, isActiveOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения поставщиков', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('bills', { method: 'GET', queryParams: filter })
                    .then((data: Array<IBill>) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения тарифов', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch(`bills/consignmenttotals`, { method: 'GET', queryParams: filter })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения общего количества груза', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('countries', { method: 'GET', queryParams: { withTransportOnly: true } })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения стран', ex, () => d(userLoaded(undefined)));
                    }),

                await serverFetch('cargocarriers/air', { method: 'GET' })
                    .then((data) => {
                        return data;
                    })
                    .catch((ex) => {
                        exception(api, 'Ошибка получения авиаперевозчиков', ex, () => d(userLoaded(undefined)));
                    }),
            ];

            Promise.all([promises]).then((result) => {
                if (cleanup) return;

                setTruck(result[0][0]);
                setCities(result[0][1]);
                setTariffSettings(result[0][2]);
                setContractors(result[0][3]);
                setBills(result[0][4]);
                setConsignmentTotals(result[0][5]);
                setCountries(result[0][6].filter((c: ICountry) => c.generateTariff));
                setAirCargoCarriers(result[0][7]);

                setLoading(false);
                setRefreshRequired(false);
            });
        };

        fetchData();
        return () => {
            cleanup = true;
        };
    }, [refreshRequired]);

    useEffect(() => {
        if (!filter || !filter.truckIds?.find((t) => t === truckId)) {
            d(setFilter(initFilter, filterContext));
            return;
        }

        delayAction(() => setRefreshRequired(true));
    }, [filter]);

    useEffect(() => {
        if (!loading && lastSelectedKey) {
            tableRef.current?.scrollTo({ key: lastSelectedKey });
            setLastSelectedKey(undefined);
        }
    }, [loading]);

    useEffect(() => {
        if (!bills) return;

        let total: IConsignmentTotals = { totalBoxQty: 0, totalVolumeWeight: 0, totalPriceUsd: 0, totalFlightPriceUsd: 0 };

        bills.map((b) => {
            if (b.unitType == UnitType.Weight) {
                total.totalBoxQty += b.qty || 0;
                total.totalVolumeWeight += b.volumeWeight || 0;

                if (b.cargoCarrierId) {
                    let setting = tariffSettings.find((s) => s.consigneeId == b?.consigneeId && s.countryId == b?.countryId);
                    let weight: number = setting && setting.weightType == WeightType.Gross ? b.grossWeight || 0 : b.volumeWeight || 0;
                    total.totalFlightPriceUsd += (b.flightPrice || 0) * weight;
                }
            } else if (b.unitType == UnitType.Pallet) {
                if (b.packages && b.packages.length > 0) {
                    b.packages.map((p) => (total.totalBoxQty += p.boxQty || 0));
                } else {
                    total.totalBoxQty += Math.ceil(b.adjustmentQty || 0);
                }
            } else if (b.unitType == UnitType.Cart) {
                total.totalBoxQty += Math.ceil(b.adjustmentQty || 0);
            }

            total.totalPriceUsd += b.totalPrice || 0;
        });

        setProcessedTotals(total);
    }, [bills]);

    useEffect(() => {
        if (currentBill && currentBill.packages) setPackages(currentBill.packages);
        else setPackages([]);
    }, [currentBill]);

    const onTariffUpdate = (entity: IBill) => {
        serverFetch(`bills/tariff`, { method: 'PUT', bodyData: entity })
            .then(() => {
                setLastSelectedKey(entity.id);

                editTariffForm.resetFields();

                setEditTariffOpen(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onAwbTariffUpdate = (entity: IBill) => {
        if (!currentBill) return;

        serverFetch(`bills/awbtariff`, { method: 'PUT', bodyData: entity })
            .then(() => {
                awbForm.resetFields();

                setAwbOpen(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка изменения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onBulkUpdate = (entity: IBill) => {
        serverFetch(`bills/bulktariff`, { method: 'PUT', bodyData: { ids: selectedIds, ...entity } })
            .then(() => {
                bulkTariffForm.resetFields();

                setBulkTariffOpen(false);
                setRefreshRequired(true);
            })
            .catch((ex) => {
                exception(api, 'Ошибка массового изменения тарифа', ex, () => d(userLoaded(undefined)));
            });
    };

    const onCalculate = () => {
        setLoading(true);

        let boxGroupIds: Array<string> = [];
        selectedIds.map((id) => {
            let bill = bills.find((t) => t.id === id);
            if (bill && bill.boxGroupId) boxGroupIds.push(bill.boxGroupId);
        });
        serverFetch(`bills/${truckId}/calculate`, { method: 'POST', bodyData: boxGroupIds })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка расчета тарифов', ex, () => d(userLoaded(undefined)));
            });
    };

    const onSavePackages = () => {
        setLoading(true);

        let entities = packages.filter((p) => !!p.boxQty);

        serverFetch(`bills/${currentBill?.id}/packages`, { method: 'POST', bodyData: entities })
            .then(() => {
                setOpenPackageDialog(false);
                setRefreshRequired(true);
                setCurrentBill(undefined);
                setSelectedIds([]);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка добавления коробок', ex, () => d(userLoaded(undefined)));
            });
    };

    const onComplete = () => {
        setLoading(true);

        serverFetch(`bills/complete`, { method: 'POST', bodyData: selectedIds })
            .then(() => {
                setRefreshRequired(true);
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка проведения тарифов', ex, () => d(userLoaded(undefined)));
            });
    };

    const renderToolbar = () => {
        return (
            <Toolbar
                commands={[
                    {
                        label: 'Обновить',
                        key: 'refresh',
                        disabled: loading,
                        icon: <ReloadOutlined />,
                        onClick: () => {
                            setRefreshRequired(true);
                        },
                    },
                    {
                        label: 'Изменить счет',
                        key: 'edit',
                        disabled: !selectedIds || selectedIds.length <= 0,
                        icon: <EditOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageTransportBill) &&
                                !hasPermission(userSession.permissions, Permission.EditTransportBill)
                            ) {
                                securityRestriction(api, [Permission.ManageTransportBill, Permission.EditTransportBill]);
                                return;
                            }

                            if (selectedIds.length === 1) {
                                editTariffForm.setFieldsValue({
                                    id: currentBill?.id,
                                    boxGroupId: currentBill?.boxGroupId,
                                    adjustmentQty: currentBill?.adjustmentQty,
                                    cityId: currentBill?.cityId,
                                    cargoCarrierId: currentBill?.cargoCarrierId,
                                    volumeWeight: currentBill?.volumeWeight,
                                    grossWeight: currentBill?.grossWeight,
                                    flightPrice: currentBill?.flightPrice,
                                    truckPrice: currentBill?.truckPrice,
                                    totalPrice: currentBill?.totalPrice,
                                    isTotalPriceManual: currentBill?.isTotalPriceManual,
                                    comment: currentBill?.comment,
                                });

                                setEditTariffOpen(true);
                            } else {
                                setBulkTariffOpen(true);
                            }
                        },
                    },
                    {
                        label: 'Рассчитать',
                        key: 'calculate',
                        disabled: loading,
                        icon: <CalculatorIcon />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportBill)) {
                                securityRestriction(api, [Permission.ManageTransportBill]);
                                return;
                            }

                            modal.confirm({
                                title:
                                    selectedIds.length <= 0
                                        ? `Рассчитать базовый тариф для новых маркировок?`
                                        : 'Сбросить выбранные строки к базовым значениям тарифа?',
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onCalculate();
                                },
                            });
                        },
                    },
                    {
                        label: 'AWB',
                        key: 'awb',
                        disabled: loading || !currentBill || !currentBill.awbNumber,
                        icon: <AirplaneIcon style={{ color: '#000000' }} />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportBill)) {
                                securityRestriction(api, [Permission.ManageTransportBill]);
                                return;
                            }

                            awbForm.setFieldsValue({
                                awbNumber: currentBill?.awbNumber,
                                flightPrice: currentBill?.flightPrice,
                            });

                            setAwbOpen(true);
                        },
                    },
                    {
                        label: 'Коробки',
                        key: 'boxes',
                        disabled: loading || !currentBill || currentBill?.unitType != UnitType.Pallet,
                        icon: <AppstoreAddOutlined />,
                        onClick: () => {
                            if (
                                !hasPermission(userSession.permissions, Permission.ManageTransportBill) &&
                                !hasPermission(userSession.permissions, Permission.EditTransportBill)
                            ) {
                                securityRestriction(api, [Permission.ManageTransportBill, Permission.EditTransportBill]);
                                return;
                            }

                            setOpenPackageDialog(true);
                        },
                    },
                    {
                        label: 'Провести',
                        key: 'completed',
                        disabled: selectedIds.length <= 0,
                        icon: <CheckOutlined />,
                        onClick: () => {
                            if (!hasPermission(userSession.permissions, Permission.ManageTransportBill)) {
                                securityRestriction(api, [Permission.ManageTransportBill]);
                                return;
                            }

                            modal.confirm({
                                title: `Провести выбранные тарифы?`,
                                icon: <QuestionCircleOutlined />,
                                okType: 'primary',
                                okText: 'ОК',
                                cancelText: 'Отмена',
                                onOk: () => {
                                    onComplete();
                                },
                            });
                        },
                    },
                ]}
                farCommands={[
                    {
                        label: 'Фильтр',
                        key: 'filter',
                        type: showFilter ? 'primary' : '',
                        icon: <FilterFilled />,
                        onClick: () => {
                            setShowFilter(!showFilter);
                        },
                    },
                ]}
            />
        );
    };

    const renderFilter = () => {
        return (
            <div ref={filterRef}>
                <Filter
                    display={showFilter}
                    items={[
                        <Input
                            style={{ width: 130 }}
                            key='consigneeCode'
                            placeholder='ID клиента'
                            value={filter?.consigneeCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, consigneeCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='markingCode'
                            placeholder='Маркировка'
                            value={filter?.markingCode}
                            onChange={(data) => {
                                d(setFilter({ ...filter, markingCode: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 230 }}
                            key='legalName'
                            placeholder='Контрагент'
                            value={filter?.legalName}
                            onChange={(data) => {
                                d(setFilter({ ...filter, legalName: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            maxTagCount='responsive'
                            key='contractor'
                            placeholder='Поставщик'
                            value={filter?.contractorIds}
                            size='middle'
                            mode='multiple'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, contractorIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={contractors.map((c) => {
                                return { value: c.id, label: c.login };
                            })}
                        />,
                        <Select
                            key='country'
                            placeholder='Страна'
                            mode='multiple'
                            maxTagCount='responsive'
                            value={filter?.countryIds}
                            size='middle'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, countryIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={countries.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />,
                        <Select
                            key='cargo'
                            placeholder='Авиаперевозчик'
                            mode='multiple'
                            maxTagCount='responsive'
                            value={filter?.cargoCarrierIds}
                            size='middle'
                            allowClear={true}
                            style={{ width: 200 }}
                            showSearch
                            onChange={(value) => d(setFilter({ ...filter, cargoCarrierIds: value }, filterContext))}
                            optionFilterProp='children'
                            filterOption={(input, option) => (option?.label as string).toLowerCase().startsWith(input.toLowerCase())}
                            filterSort={(a, b) => (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())}
                            options={airCargoCarriers.map((c) => {
                                return { value: c.id, label: c.name };
                            })}
                        />,
                        <Input
                            style={{ width: 130 }}
                            key='awbNumber'
                            placeholder='AWB'
                            value={filter?.awbNumber}
                            onChange={(data) => {
                                d(setFilter({ ...filter, awbNumber: data.target.value }, filterContext));
                            }}
                        />,
                        <Input
                            style={{ width: 160 }}
                            key='tradingPlatform'
                            placeholder='Торговая площадка'
                            value={filter?.tradingPlatform}
                            onChange={(data) => {
                                d(setFilter({ ...filter, tradingPlatform: data.target.value }, filterContext));
                            }}
                        />,
                        <Select
                            key='status'
                            placeholder='Статус счета'
                            value={filter?.statuses}
                            size='middle'
                            mode='multiple'
                            maxTagCount='responsive'
                            allowClear
                            style={{ width: 250 }}
                            onChange={(value) => d(setFilter({ ...filter, statuses: value }, filterContext))}
                            options={statuses.map((s) => {
                                return { value: s.value, label: s.label };
                            })}
                        />,
                        <Form.Item
                            key='withFlightOnly'
                            label={
                                <Tooltip title='Только с перелетом'>
                                    <Flex align='center'>
                                        <AirplaneIcon style={{ color: '#000000' }} />
                                    </Flex>
                                </Tooltip>
                            }
                            style={{ margin: 0 }}
                        >
                            <Switch
                                checked={filter?.withFlightOnly}
                                onChange={(value: any) => {
                                    d(setFilter({ ...filter, withFlightOnly: value }, filterContext));
                                }}
                            />
                        </Form.Item>,
                    ]}
                    onClear={() => d(setFilter(initFilter, filterContext))}
                />
            </div>
        );
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedIds(selectedRowKeys);

        if (selectedRowKeys.length == 1) {
            let entity = bills.find((o) => o.id == selectedRowKeys[0]);
            setCurrentBill(entity);
        } else {
            setCurrentBill(undefined);
        }
    };

    const onUpload = async (id: string, file: Blob): Promise<string> => {
        const formData = new FormData();
        formData.append('file', file);

        return serverFetch(`bills/${id}/invoice/upload`, { method: 'POST', body: formData })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка загрузки файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const onRemove = (id: string): Promise<string> => {
        return serverFetch(`bills/documents/file/${id}/delete`, { method: 'DELETE' })
            .then((data) => {
                return data;
            })
            .catch((ex) => {
                setLoading(false);
                exception(api, 'Ошибка удаления файла', ex, () => d(userLoaded(undefined)));
                return undefined;
            });
    };

    const handleChange: UploadProps['onChange'] = async ({ file, fileList, event }) => {
        if (!file) return;

        var tariffId = file.response;

        if (file.status == 'uploading' && event === undefined) {
            var result = await onUpload(tariffId, file.originFileObj as Blob);

            if (result) {
                file.status = 'done';
                file.uid = result;
            }
        } else if (file.status == 'removed') {
            var result = await onRemove(file.uid);
            if (result) {
                file.status = 'done';
            }
        }

        let tmpTariffs = [...bills];
        let tariff = tmpTariffs.find((t) => t.id == tariffId);
        if (tariff) {
            tariff.documents = fileList;

            setBills(tmpTariffs);
        }
    };

    const handlePreview = async (file: any) => {
        if (file) {
            if (!file.url && !file.preview) {
                var fileObj = file.originFileObj;

                if (typeof fileObj !== 'object') {
                    const buffer = new Buffer.from(file.originFileObj, 'base64');
                    fileObj = new Blob([buffer], { type: 'application/pdf' });
                }
            }

            var fileURL = URL.createObjectURL(fileObj);
            window.open(fileURL);
        }
    };

    const renderTable = () => {
        const columns: ColumnsType<IBill> = [
            {
                title: 'ID',
                width: 60,
                align: 'center',
                render: (_, record) => {
                    return (
                        <>
                            <div>{record.consigneeCode}</div>
                            {(record.status == BillStatus.Completed ||
                                record.status == BillStatus.ToVerify ||
                                record.status == BillStatus.Paid ||
                                record.status == BillStatus.Abandoned) && (
                                <div>
                                    <Tooltip
                                        placement='top'
                                        title={
                                            record.status == BillStatus.Completed ||
                                            record.status === BillStatus.Paid ||
                                            record.status == BillStatus.Abandoned
                                                ? 'Расчет Проведен'
                                                : 'Счет ожидает проверки'
                                        }
                                        color={
                                            record.status == BillStatus.Completed ||
                                            record.status === BillStatus.Paid ||
                                            record.status == BillStatus.Abandoned
                                                ? 'var(--main-green)'
                                                : 'darkorange'
                                        }
                                    >
                                        <>
                                            {(record.status == BillStatus.Completed ||
                                                record.status === BillStatus.Paid ||
                                                record.status == BillStatus.Abandoned) && (
                                                <CheckCircleOutlined
                                                    style={{
                                                        color: 'var(--main-green)',
                                                        fontSize: 18,
                                                        marginTop: 5,
                                                    }}
                                                />
                                            )}

                                            {record.status == BillStatus.ToVerify && (
                                                <Tooltip placement='top' title='Счет ожидает проверки' color='#f6546a'>
                                                    <WarningOutlined
                                                        style={{
                                                            color: '#f6546a',
                                                            fontSize: 18,
                                                        }}
                                                    />
                                                </Tooltip>
                                            )}
                                        </>
                                    </Tooltip>
                                </div>
                            )}
                            {record.fromPlatina && <Tag color='#000000'>#PL</Tag>}
                        </>
                    );
                },
            },
            {
                width: 40,
                align: 'center',
                render: (_, record) => {
                    return (
                        <>
                            {(record.consignmentComment || record.comment) && (
                                <Tooltip
                                    placement='topLeft'
                                    title={
                                        <Flex vertical>
                                            {record.consignmentComment && (
                                                <div>
                                                    <div style={{ fontWeight: 600, textDecoration: 'underline' }}>Поставка:</div>
                                                    <div>{record.consignmentComment}</div>
                                                </div>
                                            )}
                                            {record.comment && (
                                                <div>
                                                    <div style={{ fontWeight: 600, textDecoration: 'underline' }}>Счет:</div>
                                                    <div>{record.comment}</div>
                                                </div>
                                            )}
                                        </Flex>
                                    }
                                >
                                    <Button style={{ width: 'fit-content' }} className='comment-btn' type='link' icon={<MessageFilled />} />
                                </Tooltip>
                            )}
                        </>
                    );
                },
            },
            {
                title: '',
                width: 400,
                render: (_: any, record) => {
                    return (
                        <Form colon={false} labelCol={{ xs: 8 }} wrapperCol={{ xs: 16 }}>
                            <Form.Item label='Маркировка' style={{ marginBottom: 0 }}>
                                <span style={{ fontWeight: 600 }}>{record.markingCode}</span>
                            </Form.Item>
                            <Form.Item label='Контрагент' style={{ marginBottom: 0 }}>
                                <span style={{ fontWeight: 600 }}>{record.legalName}</span>
                            </Form.Item>
                            <Form.Item label='Расчетный город' style={{ marginBottom: 0 }}>
                                <span style={{ fontWeight: 600 }}>{record.cityName}</span>
                            </Form.Item>
                            {record.contractorName && (
                                <Form.Item label='Поставщик' style={{ marginBottom: 0 }}>
                                    <span style={{ fontWeight: 600 }}>{record.contractorName}</span>
                                </Form.Item>
                            )}
                        </Form>
                    );
                },
            },
            {
                title: '',
                align: 'center',
                width: 295,
                render: (_, record) => {
                    var diffQty: number = 0;
                    var useAdjustmentQty: boolean = false;

                    if (record.qty && record.unitType != UnitType.Weight) {
                        if (record.packages && record.packages.length > 0) {
                            var actualQty: number = 0;

                            record.packages &&
                                record.packages.map((p) => {
                                    actualQty += p.boxQty || 0;
                                });

                            diffQty = actualQty - record.qty;
                        } else {
                            useAdjustmentQty = true;
                        }
                    }

                    let country = countries.find((c) => c.id == record.countryId);
                    if (!country) return;

                    return (
                        <>
                            <ConsignmentCard
                                country={country}
                                api={api}
                                items={[
                                    {
                                        itemName: record.itemName,
                                        qty: record.qty,
                                        diffQty: diffQty,
                                        useAdjustmentQty: useAdjustmentQty,
                                        adjustmentQty: record.adjustmentQty,
                                        loadingOn: record.loadingOn,
                                        awbNumber: record.awbNumber,
                                        cargoCarrierName: record.cargoCarrierName,
                                    },
                                ]}
                            />

                            <Form.Item
                                wrapperCol={{ span: 24 }}
                                style={{ textAlign: 'left', marginTop: 10, border: '1px solid #d9d9d9', borderRadius: 5, padding: 5 }}
                            >
                                <Upload
                                    onChange={(value) => {
                                        value.file.response = record.id;
                                        handleChange && handleChange(value);
                                    }}
                                    onPreview={handlePreview}
                                    listType='text'
                                    multiple
                                    fileList={record.documents}
                                    accept='.pdf'
                                >
                                    <Tooltip title='Прикрепить документы'>
                                        <Button size='small' type='primary' icon={<PaperClipOutlined />}></Button>
                                    </Tooltip>
                                    {record?.documents && record.documents?.length <= 0 && (
                                        <span style={{ marginLeft: 10 }}>Прикрепить документы...</span>
                                    )}
                                </Upload>
                            </Form.Item>
                        </>
                    );
                },
            },
            {
                title: 'Вес',
                width: 180,
                align: 'center',
                render: (_: any, record) => {
                    if (record.unitType !== UnitType.Weight) return '';

                    let setting = tariffSettings.find((s) => s.consigneeId == record?.consigneeId && s.countryId == record?.countryId);

                    return (
                        <Form colon={false} labelCol={{ xs: 12 }} wrapperCol={{ xs: 12 }}>
                            <Form.Item label='Брутто' style={{ marginBottom: 0 }}>
                                {record.grossWeight && (
                                    <Flex justify='start' style={{ fontWeight: 600 }}>
                                        {record.grossWeight} <span>кг</span>
                                        {setting && setting.weightType == WeightType.Gross && (
                                            <Tooltip title='Расчетный вес'>
                                                <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                    </Flex>
                                )}
                            </Form.Item>
                            <Form.Item label='Объемный'>
                                {record.volumeWeight && (
                                    <Flex justify='start' style={{ fontWeight: 600 }}>
                                        {record.volumeWeight} <span>кг</span>
                                        {(!setting || setting.weightType == WeightType.Volume) && (
                                            <Tooltip title='Расчетный вес'>
                                                <CheckCircleFilled style={{ color: '#FFDD2D', marginLeft: 5 }} />
                                            </Tooltip>
                                        )}
                                    </Flex>
                                )}
                            </Form.Item>
                        </Form>
                    );
                },
            },
            {
                title: 'Тариф',
                width: 100,
                align: 'center',
                render: (_: any, record) => {
                    if (!record.boxGroupId) return;

                    return (
                        <TariffCard
                            cargoCarrierId={record.cargoCarrierId}
                            flightPrice={record.flightPrice}
                            truckPrice={record.truckPrice}
                        />
                    );
                },
            },
            {
                title: 'Упаковка',
                width: 280,
                onCell: () => ({
                    style: {
                        padding: 0,
                    },
                }),
                render: (_: any, record) => {
                    if (record.unitType == UnitType.Cart) {
                        return (
                            <Tag color='#780606' style={{ fontSize: 14, marginLeft: 5, marginTop: 5, marginBottom: 5 }}>
                                Телега
                            </Tag>
                        );
                    } else if (record.unitType == UnitType.Pallet) {
                        return record.packages?.map((p) => {
                            return (
                                <Tag
                                    key={p.templateId}
                                    color='green'
                                    style={{ fontSize: 14, marginLeft: 5, marginTop: 5, marginBottom: 5, color: '#000000' }}
                                >
                                    <span style={{ fontWeight: 600 }}>{p.templateName}</span>
                                    <span> : {p.boxQty}</span>
                                    {p.comment && (
                                        <Tooltip title={p.comment}>
                                            <MessageFilled style={{ color: 'var(--primary-color)' }} />
                                        </Tooltip>
                                    )}
                                </Tag>
                            );
                        });
                    }
                },
            },
            {
                title: 'Итого ($)',
                width: 120,
                align: 'center',
                onCell: (record) => ({
                    style: {
                        background: '#FFEE96',
                        fontWeight: 600,
                    },
                }),
                render: (_: any, record) => {
                    return (
                        <Flex gap='small' justify='center' style={{ color: record.totalPrice <= 0 ? 'red' : '' }}>
                            {record.totalPrice && toFinanceString(record.totalPrice, 2)}
                            {record.isTotalPriceManual && (
                                <Tooltip title='Счет изменен вручную'>
                                    <FormOutlined />
                                </Tooltip>
                            )}
                        </Flex>
                    );
                },
            },
            {},
        ];

        return (
            <Table
                rowKey='id'
                ref={tableRef}
                size='small'
                rowSelection={{
                    selectedRowKeys: selectedIds,
                    onChange: onSelectChange,
                    type: 'checkbox',
                    columnWidth: 35,
                }}
                columns={columns}
                dataSource={bills}
                loading={{
                    spinning: loading,
                    indicator: <LoadingOutlined style={{ fontSize: 44 }} spin />,
                }}
                onRow={(record: IBill) => {
                    return {
                        onClick: (event) => {
                            if (!record.id) return;

                            onSelectChange([record.id]);
                        },
                    };
                }}
                pagination={false}
                virtual={true}
                scroll={{ y: height ?? 0 + (showFilter ? 0 : 58) }}
                footer={() => (
                    <span
                        style={{
                            fontWeight: 600,
                        }}
                    >
                        <span>
                            Кол-во мест:
                            <span style={{ marginLeft: 5 }}>{consignmentTotals?.totalBoxQty}</span>/
                            <span
                                style={{
                                    color: consignmentTotals?.totalBoxQty == processedTotals.totalBoxQty ? 'green' : 'red',
                                    marginLeft: 2,
                                    marginRight: 2,
                                }}
                            >
                                {processedTotals.totalBoxQty}
                            </span>
                        </span>
                        <span style={{ marginLeft: 15 }}>
                            Объемный вес:
                            <span style={{ marginLeft: 5 }}>{consignmentTotals?.totalVolumeWeight}</span>/
                            <span
                                style={{
                                    color: consignmentTotals?.totalVolumeWeight == processedTotals.totalVolumeWeight ? 'green' : 'red',
                                    marginLeft: 2,
                                    marginRight: 2,
                                }}
                            >
                                {processedTotals.totalVolumeWeight.toFixed(2)}
                            </span>
                        </span>
                        <span style={{ marginLeft: 15 }}>
                            Перелет ($):
                            <span style={{ marginLeft: 5, color: 'var(--main-green)' }}>
                                {toFinanceString(processedTotals.totalFlightPriceUsd, 2)}
                            </span>
                        </span>

                        <span style={{ marginLeft: 15 }}>
                            Итого ($):
                            <span style={{ marginLeft: 5 }}>{toFinanceString(processedTotals?.totalPriceUsd, 2)}</span>
                        </span>
                    </span>
                )}
            />
        );
    };

    return (
        <div ref={containerRef} style={{ height: `calc(100vh - 290px - ${filterHeight}px)` }}>
            <FormHeader title={<>Машина "{truck?.number}" - Тарифы</>} />
            {renderToolbar()}
            {renderFilter()}
            {renderTable()}

            <Modal
                title='Массовое редактирование тарифа'
                width={650}
                open={bulkTariffOpen}
                okText='ОК'
                onOk={() => bulkTariffForm.submit()}
                onCancel={() => {
                    bulkTariffForm.resetFields();

                    setBulkTariffOpen(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onFinish={onBulkUpdate}
                    form={bulkTariffForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item label='Расчетный город'>
                        <Space.Compact style={{ width: '100%' }}>
                            <Form.Item name='setCityRequired' style={{ width: 30 }} valuePropName='checked'>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                name='cityId'
                                rules={[{ required: setBulkCityRequired, message: 'Выберите город' }]}
                                style={{ width: 'calc(100% - 30px)' }}
                            >
                                <Select
                                    disabled={!setBulkCityRequired}
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={cities.map((c) => {
                                        return { value: c.id, label: c.name };
                                    })}
                                ></Select>
                            </Form.Item>
                        </Space.Compact>
                    </Form.Item>

                    <Form.Item label='Тариф'>
                        <Flex gap='middle'>
                            <Form.Item name='flightPrice'>
                                <InputNumber
                                    decimalSeparator=','
                                    addonAfter={<AirplaneIcon />}
                                    min={0}
                                    step='0.1'
                                    stringMode
                                    precision={2}
                                />
                            </Form.Item>
                            <Form.Item name='truckPrice'>
                                <InputNumber decimalSeparator=',' addonAfter={<TruckIcon />} min={0} step='0.1' precision={2} />
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                width={650}
                title={`Изменить "${currentBill?.markingCode}"`}
                open={editTariffOpen}
                okText='ОК'
                onOk={() => editTariffForm.submit()}
                onCancel={() => {
                    editTariffForm.resetFields();
                    setEditTariffOpen(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onFinish={onTariffUpdate}
                    form={editTariffForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item name='id' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item name='boxGroupId' hidden={true}>
                        <Input />
                    </Form.Item>

                    <Form.Item label='Расчетный город' style={{ marginBottom: 0 }}>
                        <Flex gap='small'>
                            <Form.Item name='setCityRequired' valuePropName='checked'>
                                <Checkbox />
                            </Form.Item>
                            <Form.Item
                                name='cityId'
                                rules={[{ required: setCityRequired, message: 'Выберите город' }]}
                                style={{ width: '100%' }}
                            >
                                <Select
                                    disabled={!setCityRequired}
                                    showSearch
                                    allowClear
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={cities.map((c) => {
                                        return { value: c.id, label: c.name };
                                    })}
                                ></Select>
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                    {currentBill?.unitType == UnitType.Weight && (
                        <>
                            <Form.Item label='Вес Брутто' style={{ marginBottom: 0 }}>
                                <Flex gap='middle'>
                                    <Flex gap='small'>
                                        <Form.Item name='setGrossWeightRequired' valuePropName='checked'>
                                            <Checkbox />
                                        </Form.Item>
                                        <Form.Item
                                            name='grossWeight'
                                            rules={[{ required: setGrossWeightRequired, message: 'Укажите вес Брутто' }]}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                                decimalSeparator=','
                                                min={0}
                                                stringMode
                                                precision={2}
                                                disabled={!setGrossWeightRequired}
                                                onChange={(value: number | null) => {
                                                    let grossWeight = value || 0;
                                                    let setting = tariffSettings.find(
                                                        (s) =>
                                                            s.consigneeId == currentBill?.consigneeId &&
                                                            s.countryId == currentBill?.countryId
                                                    );

                                                    if (setting && setting.weightType == WeightType.Gross) {
                                                        let totalPrice =
                                                            ((editTariffForm.getFieldValue('flightPrice') || 0) +
                                                                editTariffForm.getFieldValue('truckPrice')) *
                                                            grossWeight;

                                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Flex>

                                    <Form.Item label='Объемный вес' style={{ marginBottom: 0 }}>
                                        <Flex gap='small'>
                                            <Form.Item name='setVolumeWeightRequired' valuePropName='checked'>
                                                <Checkbox />
                                            </Form.Item>
                                            <Form.Item
                                                name='volumeWeight'
                                                rules={[{ required: setVolumeWeightRequired, message: 'Укажите объемный вес' }]}
                                            >
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                    decimalSeparator=','
                                                    min={0}
                                                    stringMode
                                                    precision={2}
                                                    disabled={!setVolumeWeightRequired}
                                                    onChange={(value: number | null) => {
                                                        let volumeWeight: number = value || 0;

                                                        let setting = tariffSettings.find(
                                                            (s) =>
                                                                s.consigneeId == currentBill?.consigneeId &&
                                                                s.countryId == currentBill?.countryId
                                                        );

                                                        if (!setting || setting.weightType == WeightType.Volume) {
                                                            let flightPrice: number = editTariffForm.getFieldValue('flightPrice') || 0;
                                                            let truckPrice: number = editTariffForm.getFieldValue('truckPrice') || 0;

                                                            let totalPrice = (+flightPrice + +truckPrice) * +volumeWeight;

                                                            editTariffForm.setFieldValue('totalPrice', +totalPrice);
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Flex>
                                    </Form.Item>
                                </Flex>
                            </Form.Item>

                            <Form.Item label='Авиаперевозчик' name='cargoCarrierId' wrapperCol={{ span: 8 }}>
                                <Select
                                    allowClear={true}
                                    onChange={(value) => {
                                        editTariffForm.setFieldValue('cargoCarrierId', value);
                                    }}
                                    filterOption={(input, option) =>
                                        (option?.label as string).toLowerCase().startsWith(input.toLowerCase())
                                    }
                                    filterSort={(a, b) =>
                                        (a?.label as string).toLowerCase().localeCompare((b?.label as string).toLowerCase())
                                    }
                                    options={airCargoCarriers
                                        .filter((c) => c.countryId == currentBill.countryId)
                                        .map((c) => {
                                            return { value: c.id, label: c.name };
                                        })}
                                />
                            </Form.Item>
                        </>
                    )}

                    {currentBill?.unitType != UnitType.Weight && (!currentBill?.packages || currentBill.packages.length <= 0) && (
                        <Form.Item label={currentBill?.unitType == UnitType.Cart ? 'Телега' : 'Паллет'} style={{ marginBottom: 0 }}>
                            <Form.Item name='adjustmentQty'>
                                <InputNumber
                                    decimalSeparator=','
                                    addonAfter='шт'
                                    min={0}
                                    step='0.1'
                                    stringMode
                                    precision={2}
                                    onChange={(value: number | null) => {
                                        let adjustmentQty: number = value || 0;
                                        let totalPrice = adjustmentQty * editTariffForm.getFieldValue('truckPrice');

                                        editTariffForm.setFieldValue('adjustmentQty', adjustmentQty);
                                        editTariffForm.setFieldValue('totalPrice', +totalPrice);
                                    }}
                                />
                            </Form.Item>
                        </Form.Item>
                    )}

                    <Form.Item label='Тариф' style={{ marginBottom: 0 }}>
                        <Flex gap='middle'>
                            <Form.Item name='flightPrice' style={{ marginBottom: 0 }}>
                                <InputNumber
                                    decimalSeparator=','
                                    disabled={currentBill?.unitType != UnitType.Weight || isTotalPriceManual}
                                    addonAfter={
                                        <Tooltip title='Перелет' placement='topRight'>
                                            <div>
                                                <AirplaneIcon />
                                            </div>
                                        </Tooltip>
                                    }
                                    min={0}
                                    step='0.1'
                                    stringMode
                                    precision={2}
                                    onChange={(value: number | null) => {
                                        let flightPrice: number | null = value;

                                        let setting = tariffSettings.find(
                                            (s) => s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                        );

                                        let weight: number =
                                            setting && setting.weightType == WeightType.Gross
                                                ? editTariffForm.getFieldValue('grossWeight') || 0
                                                : editTariffForm.getFieldValue('volumeWeight') || 0;

                                        let truckPrice: number = editTariffForm.getFieldValue('truckPrice');

                                        let totalPrice: number = (+(flightPrice || 0) + +truckPrice) * +weight;

                                        editTariffForm.setFieldValue('flightPrice', flightPrice);
                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name='truckPrice' style={{ marginBottom: 0 }}>
                                <InputNumber
                                    disabled={isTotalPriceManual}
                                    decimalSeparator=','
                                    addonAfter={
                                        <Tooltip
                                            title={
                                                currentBill?.cargoCarrierId ? 'Транспортная доставка с перелетом' : 'Транспортная доставка'
                                            }
                                            placement='topRight'
                                        >
                                            <div>
                                                <TruckIcon style={{ color: currentBill?.cargoCarrierId ? 'var(--cargo-color)' : '' }} />
                                            </div>
                                        </Tooltip>
                                    }
                                    min={0}
                                    step='0.1'
                                    stringMode
                                    precision={2}
                                    onChange={(value: number | null) => {
                                        let truckPrice: number = value || 0;

                                        let totalPrice: number = 0;
                                        if (currentBill?.unitType == UnitType.Weight) {
                                            let setting = tariffSettings.find(
                                                (s) => s.consigneeId == currentBill?.consigneeId && s.countryId == currentBill?.countryId
                                            );

                                            let weight: number =
                                                setting && setting.weightType == WeightType.Gross
                                                    ? editTariffForm.getFieldValue('grossWeight') || 0
                                                    : editTariffForm.getFieldValue('volumeWeight') || 0;

                                            let flightPrice: number = editTariffForm.getFieldValue('flightPrice') || 0;
                                            totalPrice = (+flightPrice + +truckPrice) * +weight;
                                        } else {
                                            if (currentBill?.packages && currentBill?.packages.length > 0) {
                                                currentBill?.packages?.map((p) => {
                                                    totalPrice += ((p.boxQty || 0) / p.qtyPerPallet) * truckPrice;
                                                });
                                            } else {
                                                totalPrice = editTariffForm.getFieldValue('adjustmentQty') * truckPrice;
                                            }
                                        }

                                        editTariffForm.setFieldValue('truckPrice', truckPrice);
                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item style={{ marginBottom: 0 }}>
                                <Flex gap='small'>
                                    <Tooltip title='Задать итоговую сумму вручную'>
                                        <Form.Item name='isTotalPriceManual' valuePropName='checked'>
                                            <Checkbox
                                                onChange={(value: CheckboxChangeEvent) => {
                                                    let isManual: boolean = value.target.checked;

                                                    if (!isManual) {
                                                        let totalPrice: number = 0;
                                                        let flightPrice: number = editTariffForm.getFieldValue('flightPrice') || 0;
                                                        let truckPrice: number = editTariffForm.getFieldValue('truckPrice') || 0;

                                                        if (currentBill?.unitType == UnitType.Weight) {
                                                            let setting = tariffSettings.find(
                                                                (s) =>
                                                                    s.consigneeId == currentBill?.consigneeId &&
                                                                    s.countryId == currentBill?.countryId
                                                            );

                                                            let weight: number =
                                                                setting && setting.weightType == WeightType.Gross
                                                                    ? editTariffForm.getFieldValue('grossWeight') || 0
                                                                    : editTariffForm.getFieldValue('volumeWeight') || 0;

                                                            totalPrice = (+flightPrice + +truckPrice) * +weight;
                                                        } else {
                                                            if (currentBill?.packages && currentBill?.packages.length > 0) {
                                                                currentBill?.packages?.map((p) => {
                                                                    totalPrice += ((p.boxQty || 0) / p.qtyPerPallet) * truckPrice;
                                                                });
                                                            } else {
                                                                totalPrice = editTariffForm.getFieldValue('adjustmentQty') * truckPrice;
                                                            }
                                                        }

                                                        editTariffForm.setFieldValue('totalPrice', totalPrice);
                                                    }

                                                    editTariffForm.setFieldValue('isTotalPriceManual', isManual);
                                                }}
                                            />
                                        </Form.Item>
                                    </Tooltip>

                                    <Form.Item
                                        name='totalPrice'
                                        rules={[{ required: isTotalPriceManual, message: 'Укажите итоговую сумму' }]}
                                    >
                                        <InputNumber
                                            decimalSeparator=','
                                            addonAfter={<SumIcon />}
                                            min={0}
                                            precision={2}
                                            disabled={!isTotalPriceManual}
                                        />
                                    </Form.Item>
                                </Flex>
                            </Form.Item>
                        </Flex>
                    </Form.Item>
                    <Form.Item
                        label='Комментарий'
                        name='comment'
                        rules={[{ required: isTotalPriceManual, message: 'Укажите причину ввода суммы вручную' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>

            <Modal
                destroyOnClose={true}
                width={1050}
                title={
                    <>
                        Добавить упаковку. (
                        <span
                            style={{
                                color:
                                    packages.map((e) => e.boxQty || 0).reduce((sum, a) => sum + a, 0) == currentBill?.qty ? 'green' : 'red',
                            }}
                        >
                            {packages.map((e) => e.boxQty || 0).reduce((sum, a) => sum + a, 0)}
                        </span>
                        / {currentBill?.qty})
                    </>
                }
                open={openPackageDialog}
                okText='ОК'
                closable={false}
                onOk={() => {
                    onSavePackages();
                }}
                onCancel={() => {
                    setCurrentBill(undefined);
                    setSelectedIds([]);

                    setOpenPackageDialog(false);
                }}
            >
                <Packages
                    dataSource={packages}
                    onChange={(e) => {
                        setPackages(e);
                    }}
                />
            </Modal>

            <Modal
                width={450}
                title={`Изменить тариф для AWB "${currentBill?.awbNumber}"`}
                open={awbOpen}
                okText='ОК'
                onOk={() => awbForm.submit()}
                onCancel={() => {
                    awbForm.resetFields();
                    setAwbOpen(false);
                }}
            >
                <Form
                    colon={false}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onFinish={onAwbTariffUpdate}
                    form={awbForm}
                    style={{ marginTop: 20 }}
                >
                    <Form.Item name='awbNumber' hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Тариф' name='flightPrice'>
                        <InputNumber decimalSeparator=',' addonAfter={<AirplaneIcon />} min={0} step='0.1' stringMode />
                    </Form.Item>
                </Form>
            </Modal>

            {contextHolder}
            {modalContextHolder}
        </div>
    );
};

export default Tariffs;
